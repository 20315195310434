<template>
  <FocusTrap>
    <div id="mycard" class="card">

<!--    <div class="card-header header-elements-inline bg-success" style="padding-top: 0px;padding-bottom: 0px;">-->
<!--      <h5>Extrusion Material Receipt</h5>-->
<!--    </div>-->

      <window-title-bar @loadData="loadData" @close_window="closeWindow"> {{setTitle()}}</window-title-bar>

      <div class="card-body" style="background-color: #f8f9fa;">

      <div class="row">

        <div class="col-md-1">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Shift</label>
            <select id="cmbshift" class="form-control" autofocus="" v-if="voucher"  v-model="voucher.shift_id" autofocus>
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Machine</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.machine.id" >
              <option v-for="machine in machines" v-bind:value="machine.id">
                {{ machine.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Operator</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.emp_id" >
              <option v-for="emp in emps" v-bind:value="emp.id">
                {{ emp.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-12">

          <!-- Static mode -->
          <div class="card" style="background-color: #f8f9ff;">

            <div class="card-body">
              <form action="#">

                <div class="form-group row">
                  <label class="col-form-label col-md-2">Yarn Container</label>

                  <div class="col-md-1">
                    <select class="form-control" v-if="voucher" v-model="voucher.yarn_container" @change="validateItem" >
                      <option value="Cone">Cone</option>
                      <option value="Babin">Babin</option>
                      <option value="Beam">Beam</option>
                    </select>
                  </div>


                  <label class="col-form-label col-md-1">Size</label>
                  <div class="col-md-1">
                    <input type="text" class="form-control text-right" maxlength="5"  placeholder="Size" v-model="voucher.yarn_size" @change="validateItem" >
                  </div>

                  <label class="col-form-label col-md-1">Quality</label>
                  <div class="col-md-1">
                    <select class="form-control" v-if="voucher" v-model="voucher.qua_id" @change="validateItem">
                      <option v-for="quality in qualities" v-bind:value="quality.id">
                        {{ quality.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Color</label>
                  <div class="col-md-2">
                    <select class="form-control" v-if="voucher" v-model="voucher.col_id" @change="validateItem">
                      <option v-for="color in colorcharts" v-bind:value="color.id">
                        {{ color.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Qty</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Qty" min="0"  v-if="voucher" v-model="voucher.qty"  >
                  </div>

                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-6">Wastage (Kgs)</label>

                  <label class="col-form-label col-md-2 text-right">Lumps Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter Lumps Weight" min="0" v-if="voucher" v-model="voucher.lumps_wgt" >
                  </div>


                  <label class="col-form-label col-md-2 text-right">Yarn Waste Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter yarn Waste Weight" min="0" v-if="voucher" v-model="voucher.yarn_waste_wgt" >
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-form-label col-md-4">Raw-Material Excess (Kgs)</label>

                  <div class="col-md-4"></div>

                  <div class="col-md-4">
                    <input type="number" class="form-control text-right" placeholder="Enter Weight" min="0" v-if="voucher" v-model="voucher.raw_mat_excess" >
                  </div>
                </div>


                <div class="form-group row">

                  <label class="col-form-label col-md-1">Container</label>
                  <div class="col-md-1">
                    <input id="txtcontainer" type="text" class="form-control" placeholder="Enter Container No" maxlength="10" @keyup.enter="onContainerChanged"  :disabled="!enablecontainer"  autocomplete="off" v-model="container.name" >
                  </div>
                  <div class="col-md-1">
<!--                    <button type="button" class="btn btn-outline-danger"><i class="icon-mention mr-2"></i></button>-->
                    <button type="button" class="btn btn-outline-success btn-sm" tabindex="-1"><i class="icon-reload-alt mr-2"></i></button>
                  </div>

                  <label class="col-form-label col-md-2">Container Weight</label>
                  <div class="col-md-1">
                    <input type="text" class="form-control text-right" placeholder="Container Weight" readonly tabindex="-1" v-model="container.base_weight">
                  </div>


                  <label class="col-form-label col-md-2">Gross Container Wgt</label>
                  <div class="col-md-1">
                    <input  id="txtgrswgt" type="number" class="form-control text-right" placeholder="Enter Gross Weight" min="0" :disabled="!enablecontainer" @keyup.enter="onContainerWeightChanged" v-model="container.weight" >
                  </div>

                  <label class="col-form-label col-md-1">Yarn Wgt</label>
                  <div class="col-md-2">
                    <input type="text" class="form-control" placeholder="Container Weight" readonly tabindex="-1" v-model="container.weight - container.base_weight">
                  </div>


                </div>

              </form>
            </div>
          </div>
          <!-- /static mode -->

        </div>
      </div>

      <div class="row">
        <div class="table-responsive" style="padding: 10px;">
          <table  id="table" class="table table-bordered">

            <thead>
              <th>#</th>
              <th>Container No</th>
              <th></th>
              <th>Container Weight</th>
              <th>Gross Weight</th>
              <th>Yarn Weight</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr v-for="(container,idx) in containers" style="padding: 0px;">
              <td style="padding: 0px;">{{idx + 1}}</td>
              <td style="padding: 0px;">
                <input type="text" class="form-control" placeholder="Enter Container No" maxlength="10" v-model="container.name" @change="on_container_name_change(idx, container.name)" >
              </td>
              <td style="padding: 0px;width: 70px;">
                <button class="btn btn-outline-success" @click="on_container_name_change(idx, container.name)">Load</button>
              </td>
              <td style="padding: 0px;text-align: right"> {{container.base_weight | formatWeight}} </td>
              <td style="padding: 0px;">
                <input type="number" class="form-control text-right" placeholder="Enter Gross Weight" min="0" v-model="container.weight" >
              </td>
              <td style="padding: 0px;text-align: right">
                {{ parseFloat(container.weight) - container.base_weight | formatWeight}}
              </td>

                <td style="padding: 0px;text-align: right;width: 50px;" >
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(idx)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Remarks</label>
            <textarea id="txtremarks" class="form-control" placeholder="Remarks"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-1">
<!--          <label class="control-label text-semibold">Eb Reading</label>-->
<!--          <input type="number" class="form-control text-right" placeholder="EB Reading" min="0" v-if="voucher" v-model="voucher.eb_meter" >-->
        </div>
        <div class="col-md-5">

        </div>

        <div class="col-md-3 text-right">

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          &nbsp;
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
    </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'ExtrusionForm',
    store,
    components: {
      WindowTitleBar

    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"col_id":1,"qua_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false},"list":[]}')
      }
    },
    data () {
      return {
        enablecontainer: false,
        machines:[],
        hdpes:[],
        shifts:[],
        emps:[],
        qualities:[],
        colorcharts:[],
        finalproducts:[],
        containers:[],
        items : new Map(),
        container : {"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"qua_id":1,"col_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}},
        voucher: JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":"","quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","yarn_size":"","eb_meter":0,"list":[]}'),
        mac_id: 0,
      }
    },
    beforeMount () {
      const self = this;
      this.voucher = this.myvoucher;
      this.containers = [];
      this.voucher.list.forEach(function (d) {
        self.containers.push(d.container);
      });

      $('#cmbshift').focus();
    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self  = this;
      self.loadData();


      if(this.$data.voucher.doc_date == '0001-01-01' || this.$data.voucher.doc_date == '2000-01-01'){
        this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }

      self.$data.shifts.push({'id':2,'name':'Day'});
      self.$data.shifts.push({'id':3,'name':'Night'});

      $('.form-control-select2').select2();

      $('#cmbshift').focus();
    },
    filters:{
      formatWeight(val){
        try {
          return val.toFixed(3);
        }catch (e) {

        }
        return val;
      }
    },
    methods:{
      setTitle() {
        return this.voucher.id == 0 ? "Extrusion Material Receipt" : "Doc No: " + this.voucher.doc_no + "  Extrusion Material Updation";
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeWindow(){

        if(this.voucher.id > 1) {
          this.$emit('extrusionreceipt_window_closed');
        }else{
          this.$router.push("/")
        }

      },
      clear(){
        const self  = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":"","quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","yarn_size":"","eb_meter":0,"list":[]}');
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.containers = [];
      },
      validateItem() {
        const self = this;

        self.$data.enablecontainer = self.$data.voucher.col_id > 0 && self.$data.voucher.qua_id > 0 ;

      },
      onContainerChanged(){

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        if(self.$data.container.name.trim().length <= 1){
          return;
        }


        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${self.$data.container.name}`,requestOptions).then(userService.handleResponse).then(function (resp) {

          self.$data.container =  JSON.parse('{"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"qua_id":1,"col_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}}');

          if(resp.ok) {

            self.$data.container.id = resp.data.id;
            self.$data.container.name = resp.data.name;
            self.$data.container.weight = resp.data.weight;
            self.$data.container.base_weight = resp.data.base_weight;
            self.$data.container.yarn_weight = self.$data.container.weight - resp.data.base_weight;

            if(self.$data.voucher.yarn_container === 'Cone'){
              self.$data.container.weight =  parseFloat(resp.data.weight) *  parseInt(self.$data.voucher.qty);
            }

            $('#txtgrswgt').focus();
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $("#mycard").unblock();
        });

      },
      onContainerWeightChanged(){
        const self = this;
        let alreadyexits = false;

        if(parseFloat(self.$data.container.weight) <= 0) {
          alert('Invalid Gross Weight');
          return;
        }

        self.$data.containers.forEach(function (c) {
          if(c.name == self.$data.container.name){
            alert('Container already exits');
            alreadyexits = true;
            return;
          }
        });


        if(!alreadyexits) {

          self.$data.container.yarn_weight = parseFloat(self.$data.container.weight) - parseFloat(self.$data.container.base_weight);

          self.$data.containers.push(JSON.parse(JSON.stringify(self.$data.container)));
          self.$data.container = JSON.parse('{"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"qua_id":1,"col_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}}');
        }


        $('#txtcontainer').focus();
      },
      onContainerQtyChanged(){
        const self = this;

        self.$data.containers = [];
        for (let i=0; i < self.$data.voucher.qty; i++){
          self.$data.containers.push(JSON.parse('{"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"qua_id":1,"col_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}}'));
        }
      },
      on_container_name_change(idx, name){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        $("#table").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        // let container = {"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0,"net_wgt":0}
        self.$data.containers[idx].id = 0;
        self.$data.containers[idx].name = "";
        self.$data.containers[idx].weight = 0;


        fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${name}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            self.$data.containers[idx].id = resp.data.id;
            self.$data.containers[idx].name = resp.data.name;
            self.$data.containers[idx].weight = resp.data.weight;
            self.$data.containers[idx].base_weight = resp.data.base_weight;
            self.$data.containers[idx].yarn_weight = resp.data.weight- resp.data.base_weight;
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $("#table").unblock();
        });


      },
      on_container_qty_change(idx, name){
        const self = this;

        // let container = self.$data.containers[idx] ;
        // console.log(JSON.stringify(container));
        // container.net_wgt = container.wgt - parseFloat(container.grs_wgt);
        // console.log(JSON.stringify(container));

        // self.$data.containers[idx].grs_wgt = parseFloat(container.grs_wgt);
      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };



        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.machines = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });

        self.$data.hdpes = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/items/hdpe`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.hdpes = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });

        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(  _.isArray(resp.data)){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:1500});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error", timer:1500});
        });

        self.$data.colorcharts = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.colorcharts = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });

        //Qualities
        self.$data.qualities = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.qualities = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });

        $("#mycard").unblock();


      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.$data.containers.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self  = this;


        self.$data.voucher.finyear = self.$store.state.user.finyear;
        self.$data.voucher.status = "Active";
        self.$data.voucher.cmp_id = self.$store.state.user.company.id;

        self.$data.voucher.emp_id = parseInt(self.$data.voucher.emp_id);
        self.$data.voucher.shift_id = parseInt(self.$data.voucher.shift_id);
        self.$data.voucher.mac_id = parseInt(self.$data.voucher.machine.id);


        // self.$data.voucher.yarn_container = parseInt(self.$data.voucher.yarn_container);
        self.$data.voucher.qty = parseFloat(self.$data.voucher.qty);
        self.$data.voucher.yarn_wgt = parseFloat(self.$data.voucher.yarn_wgt);

        self.$data.voucher.yarn_waste_wgt = parseFloat(self.$data.voucher.yarn_waste_wgt);
        self.$data.voucher.lumps_wgt = parseFloat(self.$data.voucher.lumps_wgt);
        self.$data.voucher.raw_mat_excess = parseFloat(self.$data.voucher.raw_mat_excess);


        let yarn_weight = 0;
        let containers = [];
        self.$data.voucher.yarn_wgt = 0;
        self.$data.containers.forEach(function (det) {

          self.$data.voucher.yarn_wgt +=  parseFloat(det.weight) - parseFloat(det.base_weight);
          let detail = {"container":{"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"qua_id":1,"col_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}},"yarn_weight":0};

          detail.container = det;
          detail.container.weight = parseFloat(det.weight);
          detail.yarn_weight = parseFloat(det.weight) - parseFloat(det.base_weight);
          containers.push(detail);

          yarn_weight += detail.yarn_weight;
        });

        self.$data.voucher.prod_kgs = yarn_weight;
        self.$data.voucher.list = containers;

        if(self.$data.voucher.mac_id < 1){
          alert("Invalid Machine");
          return
        }else if(self.$data.voucher.col_id < 1){
          alert("Invalid Color");
          return
        }else if(self.$data.voucher.qua_id < 1){
          alert("Invalid Quality");
          return
        }else if(self.$data.voucher.qty < 1){
          alert("Invalid Quantity");
          return
        }else if(self.$data.voucher.lumps_wgt < 0){
          alert("Invalid Lumps Weight");
          return
        }else if(self.$data.voucher.yarn_waste_wgt < 0){
          alert("Invalid Yarn Wastage Weight");
          return
        }else if(self.$data.voucher.raw_mat_excess < 0){
          alert("Invalid Excess Weight");
          return
        }else if(self.$data.voucher.list.length < 0){
          alert("Invalid Containers Data");
          return
        }else if(self.$data.voucher.yarn_size.length <= 0){
          alert("Invalid Yarn Size");
          return
        }

        console.log(JSON.stringify(self.$data.voucher));

        let myvoucher = JSON.parse( JSON.stringify(self.$data.voucher));
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/receipt`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500, onClose: () => {
                $('#cmbshift').focus();
              }});

            self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":"","quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","yarn_size":"","eb_meter":0,"list":[]}'),
            self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
            self.$data.containers = [];
            self.$emit('extrusion_receipt_saved', resp.data);


          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
        }).finally(function () {
          $("#mycard").unblock();
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
